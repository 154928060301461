export const CLIENT = "APPDATA_CLIENT";
export const ORDER = "APPDATA_ORDER";
export const USERNAME = "APPDATA_USER_USERNAME";
export const ADDRESS = "APPDATA_USER_ADDRESS";
export const COMPANY = "APPDATA_USER_COMPANY";
export const FACILITY = "APPDATA_USER_FACILITY";
export const INFORMATION = "APPDATA_USER_INFORMATION";
export const RECEIVER_MENU = "APPDATA_RECEIVER_MENU";
export const CLEAR_STATE = "APPDATA_CLEAR_STATE";
export const CLEAR_ORDER_STATE = "APPDATA_CLEAR_ORDER_STATE";
export const CLEAR_RECEIVER_MENU = "APPDATA_CLEAR_RECEIVER_MENU";
export const CURRENT_STEP = "APPDATA_CURRENT_STEP";
export const ORDER_MOVE_ENABLED = "APPDATA_ORDER_MOVE_ENABLED";
export const SET_IS_RECEIVE_DELIVERY_FOR_FIXED_ASSETS_ENABLED =
  "APPDATA_SET_IS_RECEIVE_DELIVERY_FOR_FIXED_ASSETS_ENABLED";
