import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useComflowLogin from "../components/Comflow/hooks/useComflowLogin";
import { useIsSingleSignOn } from "../hooks/useIsSingleSignOn";
import {
  setCompany,
  setFacility,
  setInformation,
  setIsReceiveDeliveryForFixedAssetsEnabled,
  setOrder,
  setOrderMoveEnabled,
} from "../reducers/appData/appDataActions";
import { useAppDispatch } from "../reducers/hooks/useAppDispatch";
import {
  resetSession,
  setApplicationsList,
  setAuthorizationList,
  setInitialized,
  setIsSingleSignOn,
  setLoginToken,
  setSessionId,
  setUserHasMessageToAccept,
} from "../reducers/session/sessionAction";
import {
  selectHasComflowAccess,
  selectHasFSPAccess,
  selectUserHasMessageToAccept,
} from "../reducers/session/sessionSelectors";
import { EMBEDDED_SESSION_ID, MAIN_SESSION_ID, SESSION_ID } from "../reducers/session/sessionVariables";
import accountService from "../services/account/accountService";
import storage from "../utils/store/storage";
import { getIframe, isEmbedded } from "./embeddedUils";

const useInit = () => {
  const refLoginToken = useRef();
  const [stateLoginToken, setStateLoginToken] = useState();
  const sessionId = useSelector(state => state.session?.sessionId);
  const sessionInitialized = useSelector(state => state.session?.initialized);
  const applicationsList = useSelector(state => state.session.applicationsList);
  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const hasComflowAccess = useSelector(selectHasComflowAccess);
  const userHasMessageToAccept = useSelector(selectUserHasMessageToAccept);
  const [isMylocLogin, setIsMylocLogin] = useState();
  const { isSingleSignOn } = useIsSingleSignOn();
  const { initiateComflowLogin, isRedirectToComflowAllowed, isLoggedIn } = useComflowLogin();
  const [sessionIdInitialized, setSessionIdInitialized] = useState(false);

  useEffect(() => {
    if (sessionId) return;

    const initSessionId = async () => {
      //Storage is master
      const mainSessionId = await storage.loadItem(MAIN_SESSION_ID);
      const embeddedSessionId = await storage.loadItem(EMBEDDED_SESSION_ID);

      const _sessionId = isEmbedded ? embeddedSessionId : mainSessionId;

      if (_sessionId) {
        setSessionId(_sessionId);
      }
      setSessionIdInitialized(true);
    };

    initSessionId();
  }, [sessionId]);

  //Check if only authorized to Comflow, redirect if so
  useEffect(() => {
    if (!sessionId) return;

    if (!isRedirectToComflowAllowed() || !applicationsList || userHasMessageToAccept === undefined) {
      return;
    }

    const handleRedirection = async () => {
      //Need to manually delete sessionId from storage to avoid login loop if returned from Comflow
      //Cannot use logout, since we will lose the session before(?) redirecting
      //Next time user returns login screen will be shown
      await storage.deleteItem(SESSION_ID);

      window.open(process.env.REACT_APP_COMFLOW_WEBAPP, "_self");
    };

    if (!hasFSPAccess && hasComflowAccess && userHasMessageToAccept === null) {
      if (isLoggedIn) {
        handleRedirection();
      } else {
        initiateComflowLogin();
      }
    }
  }, [
    applicationsList,
    hasComflowAccess,
    hasFSPAccess,
    initiateComflowLogin,
    isLoggedIn,
    isRedirectToComflowAllowed,
    sessionId,
    userHasMessageToAccept,
  ]);

  const dispatch = useAppDispatch();

  const initializeSingleSignOnSession = useCallback(async () => {
    const response = await accountService.getAppData();

    if (response.isOk() && response.data?.id) {
      setSessionId(response.data?.id);
    } else {
      dispatch(setIsSingleSignOn(false));
    }
  }, [dispatch]);

  const querystringToken = queryString.parse(location.search).loginToken;

  useEffect(() => {
    if (!!querystringToken && querystringToken !== stateLoginToken) {
      setStateLoginToken(querystringToken);
    }
  }, [querystringToken, stateLoginToken]);

  useEffect(() => {
    if (isMylocLogin !== undefined) return;

    setIsMylocLogin(getMylocLoginToken() !== undefined);
  }, [isMylocLogin]);

  useEffect(() => {
    if (isSingleSignOn === undefined || isMylocLogin === undefined || !sessionIdInitialized) {
      return;
    }

    const doInit = async () => {
      if (sessionId && !sessionInitialized) {
        await initializeSession(dispatch, isSingleSignOn, sessionId);
      } else if (!!stateLoginToken && stateLoginToken !== refLoginToken.current) {
        setIsSingleSignOn(false); //Might be both token login AND sso, set IsSingleSignOn to false
        await doLoginWithSessionToken(dispatch, stateLoginToken, refLoginToken);
      } else if (isMylocLogin && !sessionInitialized) {
        setIsSingleSignOn(false); //Might be both token login AND sso, set IsSingleSignOn to false
        await initializeMylocLoginSession(dispatch, refLoginToken);
      } else if (isSingleSignOn && !sessionInitialized) {
        await initializeSingleSignOnSession();
      } else if (!sessionInitialized) {
        await initializeSession(dispatch, isSingleSignOn, sessionId);
      }
    };

    doInit();
  }, [
    dispatch,
    initializeSingleSignOnSession,
    isMylocLogin,
    isSingleSignOn,
    sessionId,
    sessionIdInitialized,
    sessionInitialized,
    stateLoginToken,
  ]);
};

const initSessionData = (data, dispatch) => {
  setCompany({
    id: data.company?.id,
    description: data.company?.label,
  });

  setFacility({
    id: data?.facility?.id,
    description: data?.facility?.label,
    value: data?.facility?.value,
    category: data?.facility?.category,
  });

  if (data.user) setInformation(data.user);

  if (data.order) setOrder(data.order);
  setOrderMoveEnabled(data.orderMoveEnabled);
  setIsReceiveDeliveryForFixedAssetsEnabled(data.isReceiveDeliveryForFixedAssetsEnabled);

  //Don't set sessionId here. It has already been set

  setAuthorizationList(data.authorizations);

  dispatch(setApplicationsList(data.applications));
  setUserHasMessageToAccept(data.accessMessage);
};

const doLoginWithSessionToken = async (dispatch, stateLoginToken, refLoginToken) => {
  const data = {
    token: stateLoginToken,
  };
  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithMylocToken = async (mylocLoginToken, dispatch, refLoginToken) => {
  const data = {
    mylocLoginToken: mylocLoginToken,
    persistent: false,
  };

  doLoginWithToken(data, dispatch, refLoginToken);
};

const doLoginWithToken = async (data, dispatch, refLoginToken) => {
  const token = data?.token ? data?.token : data?.mylocLoginToken;
  if (refLoginToken.current === token) return;

  refLoginToken.current = token;

  const response = await accountService.loginWithToken(data);

  if (!response.isOk()) {
    //Initalise the session without sessionId to stop spinner and show info
    initializeSession();
  }
  removeLoginTokenFromQueryString();
};

const mylocAuthentication = async (mylocLoginToken, dispatch, refLoginToken) => {
  if (!mylocLoginToken) return;

  setLoginToken(mylocLoginToken);

  await doLoginWithMylocToken(mylocLoginToken, dispatch, refLoginToken);
};

const getMylocLoginToken = () => {
  return queryString.parse(location.search).mylocLoginToken;
};

async function initializeMylocLoginSession(dispatch, refLoginToken) {
  let query = queryString.parse(location.search);
  await mylocAuthentication(query.mylocLoginToken, dispatch, refLoginToken);

  return;
}

const removeLoginTokenFromQueryString = () => {
  const iframe = getIframe();

  if (iframe) {
    const url = new URL(iframe.src, window.location.origin);

    url.searchParams.delete("loginToken");
    url.searchParams.delete("mylocLoginToken");

    //Update the iframe's src. The iframe will reload without token, but sessionId is stored and will be used at reload
    iframe.src = url.toString();
  } else {
    const url = new URL(window.location.href);

    url.searchParams.delete("loginToken");
    url.searchParams.delete("mylocLoginToken");

    window.history.replaceState({}, "", url.toString());
  }
};

async function initializeSession(dispatch, isSingleSignOn, sessionId) {
  if (sessionId || isSingleSignOn) {
    const response = await accountService.getAppData();
    if (response.isOk()) {
      initSessionData(response.data, dispatch);
    } else {
      resetSession();
      return;
    }
  }

  setInitialized();
}

export default useInit;
